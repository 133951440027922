<template>
  <SkeletonLoading v-if="globalIsLoading || isLoading"
    :height="isMobileDevice ? '72px' : '85px'"
    borderRadius="10px"
  />
  <router-link v-else class="flex flex-1" :to="to">
    <!-- Desktop version -->
    <div
      v-if="!isMobileDevice && !isGlobalSearch"
      class="market-list-element hover:opacity-80"
    >
      <div class="market-list-asset-image">
        <SquareImage v-bind="assetImgProps" />
      </div>

      <div class="w-6/12 items-center ml-s8">
        <div class="subheadline-medium">
          {{ title }}
        </div>
        <div class="subheadline-x-small mt-s4 truncate">
          {{ subTitle }}
        </div>
      </div>

      <div class="w-2/12 ml-s12 subheadline-large">
        {{ sport }}
      </div>

      <div class="w-2/12" :class="`${valueChangeIsPositive ? 'text-text-positive' : 'text-text-negative'}`">
        {{ numberFormat(valueChangePercent, 2, true) }}%
      </div>

      <div class="w-2/12">
        ${{ numberFormat(value) }}
      </div>
    </div>

    <!-- Mobile version -->
    <div
      v-else 
      class="market-list-element market-list-element-mobile hover:opacity-80"
    >
      <div class="market-list-asset-image w-1/12 flex-shrink-0">
        <SquareImage v-bind="assetImgProps"/>
      </div>

      <div class="flex items-center h-full flex-1 ml-s16">
        <div class="flex flex-1 flex-col self-start">
          <div :class="`text-n-lg ${type === 'collections' ? 'player-title whitespace-nowrap overflow-hidden overflow-ellipsis' : 'title-wrap'}`">
            {{ title }}
          </div>
          <div class="text-sm12 font-medium subtitle-wrap">
            {{ subTitle }}
          </div>
        </div>
        <div class="flex flex-col self-start items-end ml-s16">
          <div class="text-n-lg ">
            ${{ numberFormat(value) }}
          </div>

          <div
              v-if="type !== 'drops'"
              :class="`body-text-small px-1 py-1px rounded ${valueChangeIsPositive ? 'bg-background-positive-text text-text-positive' : 'bg-background-negative-text text-text-negative'}`"
          >
            {{ numberFormat(valueChangePercent, 2, true) }}%
          </div>

          <BaseText
            v-if="type === 'drops'"
            size="body-text-x-large"
          >
            {{ supply }}
          </BaseText>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import { BaseText, SquareImage } from '@/components/misc';
import { formatPercentage } from '@/utils/formatters';

export default {
  name: 'MarketListItem',

  components: {
    BaseText,
    SquareImage,
  },

  props: {
    data: [Object, Number],
    type: String,
    isGlobalSearch: Boolean,
    index: Number,
    onImageLoad: Function,
    isLoading: Boolean,
  },

  computed: {
    valueChangePercent() {
      return this.lodashGet(this.data, 'price_change.change_pct', null) ||
        this.data.change_24h_pct ||
        this.data.price_change_24h_pct ||
        this.data.asset_value_usd ||
        0;
    },

    valueChangeIsPositive() {
      return this.valueChangePercent >= 0;
    },

    title() {
      if (!this.type) {
        return '';
      }
      switch (this.type) {
        case 'collections':
          return this.data.name;
        case 'drops':
          return this.data.asset.name;
        default:
          return this.data.collection_name || this.data.name;
      }
    },
    subTitle() {
      const count = this.data.asset_count;

      switch (this.type) {
        case 'collections':
          return this.$tc('market.asset_count', count);
        case 'assets':
          return this.data.long_name || this.data.short_name;
        default:
          return '';
      }
    },
    sport() {
      switch (this.type) {
        case 'collections':
          return this.lodashGet(this.data, 'sport.name', '').replace('Cards', '');
        case 'assets':
          return this.lodashGet(this.data, 'sport', '').replace('Cards', '');
        default:
          return '';
      }
    },
    to() {
      switch (this.type) {
        case 'collections':
          return `/collection/${this.data.id_hr}`;
        case 'assets':
          return `/trade/${this.data.id_hr}`;
        case 'drops':
          return `/drop/${this.data.id}`;
        default:
          return '';
      }
    },
    supply(){
      if (this.type !== 'drops') {
        return '';
      }

      if (this.data.supply_left !== 1) {
       const supply_sold = (1 - this.data.supply_left); 
       return `${formatPercentage(supply_sold)} ${this.$t('drops.sold')}`;
      }

      return this.$t('drops.sold_out');
    },
    imgSrc() {
      switch (this.type) {
        case 'drops':
          return this.data.asset.img_front;
        default:
          return this.data.img;
      }
    },
    value() {
      if (this.data.total_value || this.data.price_per_token || this.data.asset_value_usd) {
        return this.data.total_value || this.data.price_per_token || this.data.asset_value_usd;
      } else {
        return 0;
      }
    },

    assetImgProps() {
      const isCollections = this.type === 'collections';
      const assetClass = isCollections ? '-player' : '';
      const assetType = isCollections ? '_player' : '_asset';

      return {
        image: this.imgSrc,
        imageResizeProps: {
          classes: `market-list-image${ assetClass }`,
          alt: this.data.img_alt || this.data.name,
          assetType: `market_list${ assetType }`,
          onImageLoad: this.onImageLoad,
        }
      };
    }
  }
};
</script>

<style scoped>
  .market-list-element {
    @apply 
      bg-background-primary
      border
      border-border
      cursor-pointer
      flex
      items-center
      justify-between
      px-3
      py-2-5
      rounded-10
      text-xl
      w-full;
    transition: all 300ms;
    box-sizing: border-box;
    height: 85px;
  }

  .market-list-element-mobile {
    height: 72px;
  }

  .market-list-image {
    object-fit: contain;
    width: 60px;
    height: 60px;
  }

  .market-list-image-player {
    @apply rounded-full border border-border bg-background-primary;
    object-fit: cover;
    width: 60px;
    height: 60px;
  }

  .title-wrap {
    max-width: calc(100vw - 210px);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .subtitle-wrap {
    max-width: calc(100vw - 210px);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .player-title {
    max-width: calc(100vw - 250px);
  }

  .market-list-asset-image {
    max-width: 50px;
    width: 100%
  }
</style>
