<template>
  <div :class="`flex flex-col ${!isMobileDevice ? 'p-4' : 'flex-1 p-7-5 sm:p-4 md:overflow-y-hidden'}`">
    <div class="flex justify-between">
      <Title :title="$t('search.for_players_cards_drops')"/>
    </div>

    <div class="flex flex-col flex-1 justify-start items-center -mr-4">
      <div class="flex relative items-center w-full pr-3">
        <input ref="searchRef" class="v8 mt-4 xs:mt-2" :value="search" @input="handleInput" data-testid="input-search" />
        <IconSearch v-if="!search" @click="$refs.searchRef.focus()" class="absolute w-4 h-4 cursor-pointer top-0 right-0 mt-7-8 mr-7 fill-current" />
        <CloseIcon v-else @click="clearSearch" class="absolute w-4 h-4 cursor-pointer top-0 right-0 mt-7-8 mr-7 fill-current" />
      </div>
      <div class="results-container scrollable-modal-element">
        <div v-if="!isLoading">
          <div v-if="players.length" class="pr-3">
            <div @click="toggleList('players')" class="flex justify-between items-center cursor-pointer">
              <div class="text-xl font-bold">
                {{$t('navigation.collections')}}:
                <span class="text-n-lg font-semibold">({{ $t(players.length > 1 ? 'search.results' : 'search.result', {number: players.length}) }})</span>
              </div>
              <TriangleIcon :class="`h-4 w-6 fill-current hover:opacity-80 default-transition ${list.players ? '' : 'rotate-180'}`" />
            </div>
            <div v-if="list.players">
              <div v-for="(player, index) in players" v-bind:key="index"
                class="flex justify-between items-center text-sm15 font-bold my-3">
                <MarketListItem
                  @click.native="hideModal"
                  :class="`${imagesLoading(players, imagesLoaded) ? 'hide' : 'show'}`"
                  :data="player"
                  :index="index"
                  :onImageLoad="onImageLoad"
                  type="collections"
                  isGlobalSearch
                />
              </div>
            </div>
          </div>
          <div v-if="cards.length" class="mt-2 pr-3">
            <div @click="toggleList('cards')" class="flex justify-between items-center cursor-pointer">
              <div class="text-xl font-bold">
                {{$t('navigation.assets')}}:
                <span class="text-n-lg font-semibold">({{ $t(cards.length > 1 ? 'search.results' : 'search.result', {number: cards.length}) }})</span>
              </div>
              <TriangleIcon :class="`h-4 w-6 fill-current hover:opacity-80 default-transition ${list.cards ? '' : 'rotate-180'}`" />
            </div>
            <div v-if="list.cards">
              <div v-for="(asset, index) in cards" v-bind:key="index"
                class="flex justify-between items-center text-sm15 font-bold my-3">
                <MarketListItem
                  @click.native="hideModal"
                  :class="`${imagesLoading(cards, imagesLoaded) ? 'hide' : 'show'}`"
                  :data="asset"
                  :index="index"
                  :onImageLoad="onImageLoad"
                  type="assets"
                  isGlobalSearch
                />
              </div>
            </div>
          </div>
          <div v-if="drops.length" class="mt-2 pr-3">
            <div @click="toggleList('drops')" class="flex justify-between items-center cursor-pointer">
              <div class="text-xl font-bold">
                {{$t('navigation.drops')}}:
                <span class="text-n-lg font-semibold">({{ $t(drops.length > 1 ? 'search.results' : 'search.result', {number: drops.length}) }})</span>
              </div>
              <TriangleIcon :class="`h-4 w-6 fill-current hover:opacity-80 default-transition ${list.drops ? '' : 'rotate-180'}`" />
            </div>
            <div v-if="list.drops">
              <div v-for="(drop, index) in drops" v-bind:key="index"
                class="flex justify-between items-center text-sm15 font-bold my-3">
                <MarketListItem
                  @click.native="hideModal"
                  :class="`${imagesLoading(drops, imagesLoaded) ? 'hide' : 'show'}`"
                  :data="drop"
                  :index="index"
                  :onImageLoad="onImageLoad"
                  type="drops"
                  isGlobalSearch
                />
              </div>
            </div>
          </div>
        </div>
        <Loading v-else />
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'vue-debounce';

import CloseIcon from '@/assets/icons/close.svg';
import IconSearch from '@/assets/icons/search.svg';
import Title from '@/components/misc/Title';
import TriangleIcon from '@/assets/icons/triangle.svg';
import { MarketListItem } from '@/components/market';
import { Loading } from '@/components/misc';
import GLOBAL_SEARCH from '@/graphql/queries/GlobalSearch.gql';

export default {
  name: 'GlobalSearch',
  components: {
    CloseIcon,
    IconSearch,
    Title,
    TriangleIcon,
    MarketListItem,
    Loading,
  },

  data() {
    return {
      search: '',
      isLoading: false,
      players: [],
      cards: [],
      drops: [],
      list: {
        players: true,
        cards: true,
        drops: true,
      },
      imagesLoaded: 0,
    };
  },

  async mounted() {
    this.$refs.searchRef.focus();

    this.debouncer = debounce(() => {
      this.list = { players: true, cards: true, drops: true, };

      if (this.search) {
        return this.globalSearch();
      }

      this.players = [];
      this.cards = [];
      this.drops = [];
    }, 300);

    await this.$store.dispatch('events/track', { event: 'SEARCH_VIEWED' });
  },
  methods: {
    async globalSearch() {
      try {
        this.isLoading = true;

        await this.$store.dispatch('events/track', {
          event: 'SEARCH_SUBMITTED',
          variables: {
            input_text: this.search,
          },
        });
        const response = await this.$apollo.query({
          query: GLOBAL_SEARCH,
          variables: {
            query: this.search,
          },
        });

        const { asset, drop, player } = response.data.search.reduce((cur, acc) => {
            cur[acc.__typename.toLowerCase()].push(acc);
            return cur;
        }, {
            player: [],
            asset: [],
            drop: []
        });
        const filteredAssets = asset.filter(item => !!item.pair).map(item => {
          return {
            id: item.id,
            img: item.img_front,
            name: item.name,
            short_name: item.short_name,
            collection_name: this.lodashGet(item, 'associated_players[0].name', ''),
            ticker: item.ticker,
            ...item.pair
          };
        });

        this.cards = filteredAssets;
        this.drops = drop;
        this.players = player;
      } catch (err) {
        await this.showError(err);
      } finally {
        this.isLoading = false;
      }
    },

    handleInput(e) {
      this.search = e.target.value;
      this.debouncer();
    },

    clearSearch() {
      this.search = '';
      this.debouncer();
      this.$refs.searchRef.focus();
    },

    toggleList(type) {
      this.list[type] = !this.list[type];
    },

    onImageLoad() {
      this.imagesLoaded = this.imagesLoaded + 1;
    },
  },
};
</script>

<style scoped>
.results-container {
  @apply mt-4;
  max-height: 400px;
  max-width: 538px;
  width: 100%;
}
</style>
